angular.module('CaresApp').filter('parens', [
  '$filter',
  function($filter) {
    return function(num) {
      if (num < 0) {
        return `(${$filter('number')(Math.abs(num))})`;
      }
      if (num > 0) {
        return $filter('number')(Math.abs(num));
      }
      return "0";
    };
  },
]);
