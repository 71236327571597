angular.module('CaresApp').filter('activeLookups', [
  'OcrUtils',
  function(OcrUtils) {
    return function(lookupList, date) {
      if (!date) {
        date = OcrUtils.today
      }
      const newLookupList = []
      for (const lookup of lookupList) {
        if (lookup.startDate > date) {
          continue;
        } else if (!lookup.endDate || lookup.endDate < date) {
          continue;
        } else {
          newLookupList.push(lookup)
        }
      }
      return newLookupList;
    };
  },
]);

