angular.module('CaresApp').filter('filterOffices', function() {
  return function(offices, userOfficeId) {
    const filtered = [];
    angular.forEach(offices, function(office) {
      if (office.statusId === 1 || office.id === userOfficeId) {
        filtered.push(office);
      }
    });
    return filtered;
  };
});
