angular.module('CaresApp').filter('cents', [
  '$filter',
  function($filter) {
    return function(input, thousandths, blankUndefined) {
      if (!input) {
        if (blankUndefined) {
          return null
        }
        input = 0;
      }
      let value = parseInt(input, 10);
      const denominator = thousandths ? 1000.0 : 100.0;
      if (value < 0) {
        value = Math.abs(value);
        return `(${$filter('currency')(value / denominator, undefined, thousandths ? 4 : 2)})`;
      }
      return $filter('currency')(input / denominator, undefined, thousandths ? 4 : 2);
    };
  },
]);
