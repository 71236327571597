angular.module('CaresApp').filter('phone', function() {
  'use strict';

  return function(input, _, separator) {
    if (!input) {
      return null;
    }
    if (!isNaN(input)) {
      input = input.toString();
    }

    input = input.substring(0, 10);

    separator = separator || '-';
    input = input.replace(' ', '');
    input = input.replace('(', '');
    input = input.replace(')', '');
    input = input.replace('.', '');

    const arrOut = input.split('');

    // Loop through the array and remove anything that's NaN
    for (let i = 0; i < arrOut.length; i++) {
      if (isNaN(parseInt(arrOut[i], 10))) {
        arrOut.splice(i, 1);
      }
    }

    // Put all the separators back
    arrOut.splice(0, 0, '(');
    arrOut.splice(4, 0, ') ');
    arrOut.splice(8, 0, separator);

    return arrOut.join('');
  };
});
